document.addEventListener('DOMContentLoaded', (e) => {

	let navButtons = document.querySelectorAll("[data-toggle='nav']");
	if(navButtons.length)
	{
		navButtons.forEach(btn => btn.onclick = () => document.body.classList.toggle('--is-left-menu'))
	}

    let reviewsBox = '.reviews-slider';

    if(document.querySelector(reviewsBox))
    {
        new Swiper(reviewsBox, {
            loop:true,
            pagination: {
                el: reviewsBox + ' .swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: ' .reviews__btn-next',
                prevEl: ' .reviews__btn-prev',
            },
			breakpoints: {
				// when window width is >= 320px
				640: {
					slidesPerView: 1,
					spaceBetween: 30
				},
				1100: {
					slidesPerView: 2,
					spaceBetween: 30
				}
			}
        });
    }

    let processMaking = '.process-making__gallery';


	if(document.querySelector(processMaking))
	{
		new Swiper(processMaking, {

			loop:true,
			autoplay: {
				delay: 3000,
			},
			pagination: {
				el: processMaking + ' .swiper-pagination',
				clickable: true,
			},
			breakpoints: {
				414: {
					slidesPerView: 1.2,
					spaceBetween: 30
				},
				640: {
					slidesPerView: 2.2,
					spaceBetween: 30
				},
				808: {
					slidesPerView: 3,
					spaceBetween: 30
				},
				1100: {
					slidesPerView: 4,
					spaceBetween: 30
				}
			}
		});
	}


	const lightbox = GLightbox();

})