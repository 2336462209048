<template>
	<div v-if="state.isOpen" class="v-popup" :class="[{'--is-open':state.isOpen, '--no-padding':!params.padding}, 'popup__'+params.name]">

		<div class="v-popup__bg" @click="close()"></div>
		<div class="v-popup__dialog box">
			<div class="v-popup__dialog-close" @click="close()"></div>
			<slot></slot>
		</div>

	</div>
</template>
<script>

	export default {
		name: "v-popup",
		data(){

			return {
				params:{
					padding:true,
				},
				state:{
					isOpen:false,
				}
			}
		},
		computed:{

		},
		mounted(){

			if(this.$attrs && this.$attrs.params) Object.keys(this.$attrs.params).forEach(key => this.params[key] = this.$attrs.params[key]);

			this.params.name = this.$attrs.name;

			if(!window['popups']) window['popups'] = {};
			if(this.$attrs && this.$attrs['name']) window['popups'][this.$attrs['name']] = this;
			//    this.open();

			this.$on('close', this.close);

		},
		methods:{
			open(){
				this.state.isOpen = true;
				if(document.scroll) document.scroll.disable();

				if(this.params.name == 'thanks')
				{
					let popups = Object.values(window.popups).filter(p => p.isOpen() && p.params.name != 'thanks');

					if(popups) popups.forEach(popup => popup.close());

					setTimeout(() => this.close(), 5000)

				}

			},
			close(){
				this.state.isOpen = false;
				if(document.scroll) document.scroll.enable()
			},
			isOpen(){
				return this.state.isOpen;
			}
		}
	}
</script>