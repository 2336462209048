var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.state.isOpen
    ? _c(
        "div",
        {
          staticClass: "v-popup",
          class: [
            {
              "--is-open": _vm.state.isOpen,
              "--no-padding": !_vm.params.padding
            },
            "popup__" + _vm.params.name
          ]
        },
        [
          _c("div", {
            staticClass: "v-popup__bg",
            on: {
              click: function($event) {
                return _vm.close()
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "v-popup__dialog box" },
            [
              _c("div", {
                staticClass: "v-popup__dialog-close",
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              }),
              _vm._v(" "),
              _vm._t("default")
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }