<template>
	<form class="form-state" :class="{'--is-success':state.isSuccess}" @submit.prevent="_onSubmit">
		<div>
			<input type="tel" placeholder="Номер телефона" :required="true" class="form-state__input" v-model="phone">
			<small class="form-state__small">Укажите пожалуйста корректно, иначе мы не сможем с Вами связаться</small>
		</div>
		<button class="btn --peach w100">Получить консультацию</button>
	</form>
</template>

<script>


export default {
    name: 'form-state',
    data(){
        return {
            params:{
                url:this.$parent.url + '/local/api/route.php',
                theme:'Форма на странице ЖК',
            },
            state:{
                isLoadingComponent:false,
                isLoadingSend:false,
                isSuccess:false,
            },
			phone:'',
        }
    },
    computed:{},
    mounted (){
        if(this.$attrs && this.$attrs.params)
        {
            let attrs = JSON.parse(this.$attrs.params);
            Object.keys(attrs).forEach(k => {
                this.params[k] = attrs[k];
            })
        }
    },
    methods:{
		getData(){
			let data = [];

			data.push({
				section:'Клиент',
				code:'phone',
				value:this.phone,
			})
			return data;
		},
		resetForm(){
			this.phone = '';
			setTimeout(() => this.state.isSuccess = false, 5000);
		},
		_onSubmit(){

			let params = new URLSearchParams();
			params.append('component','lead');
			params.append('action','lead');

			this.isLoadingSend = true;

			Axios.post('/app/api/'+'?'+params.toString(), this.getData()).then(responce => {
				if(responce.data && responce.data.result)
				{
					if(responce.data.result && responce.data.result.sended && responce.data.result.sended == 'Y')
					{
						this.isLoadingSend = false;
						this.resetForm();
						window.popups.thanks.open()
						window.popups.lead.close()
						console.log(responce.data.result);
					}
					else
					{
						alert('Произошла ошибка при отправке письма. Попробуйте позже.');
					}

				}

			}).catch(err => {
				console.log(err)
				alert(err)
			});

		},
       
        isMobile(){
			return window.innerWidth < 808;
        },
        removeSpace(s){
            return String(s).replace(/\s/g,'')
        },
		addSpace(n){
            n = this.removeSpace(n);
        	return String(n).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')
		},
    }
};

</script>

<style >
</style>
