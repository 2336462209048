import jquery from 'jquery';
import Vue from 'vue';
import Axios from 'axios';
import Swiper, { Navigation, Pagination, Thumbs, Autoplay } from 'swiper';

Swiper.use([Pagination, Navigation, Autoplay])

import GLightbox from 'glightbox';

import 'swiper/swiper-bundle.css';
import 'glightbox/dist/css/glightbox.css';

window.GLightbox = GLightbox;
window.jQuery = jquery;
window.$ = jquery;
window.Swiper = Swiper;
window.Axios = Axios;

Vue.component('FormState', require('../vue-components/FormState.vue').default);
Vue.component('VPopup', require('../vue-components/VPopup.vue').default);
Vue.component('VForm', require('../vue-components/VForm.vue').default);

const Root = new Vue({
	el: '#app',
	mounted(){

		this.$el.querySelectorAll('[data-modal]').forEach(button => {

			let modalName = button.getAttribute('data-modal');

			button.addEventListener('click', () => {
				let modalName = button.getAttribute('data-modal');
				console.log(window.popups);

				if(modalName && window.popups[modalName]) window.popups[modalName].open();
			})
		})

	}
});

window.Root = Root;

require('./script');
require('./y-map');