var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form-state",
      class: { "--is-success": _vm.state.isSuccess },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm._onSubmit($event)
        }
      }
    },
    [
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.phone,
              expression: "phone"
            }
          ],
          staticClass: "form-state__input",
          attrs: { type: "tel", placeholder: "Номер телефона", required: true },
          domProps: { value: _vm.phone },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.phone = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("small", { staticClass: "form-state__small" }, [
          _vm._v(
            "Укажите пожалуйста корректно, иначе мы не сможем с Вами связаться"
          )
        ])
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "btn --peach w100" }, [
        _vm._v("Получить консультацию")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }